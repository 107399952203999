<script setup>
import {useBaseStore} from "~/stores/base";
import ModuleInput from "~/components/ModuleInput.vue";
import {EMAIL_ERROR_TIPS_MAP} from "~/utils/constants";
import {emailValid} from "~/utils/common";
import {waitlistSignup} from "~/api/api.waitlist";

const emits = defineEmits(["close", "success"])

/** props **/
const props = defineProps({
  title: {
    required: true,
    default: ""
  },
  subtitle: {
    required: true,
    default: ""
  },
  desc: {
    required: true,
    default: ""
  }
})

/** data **/
const email = ref("")
const emailErrorTips = ref("")
const emailError = ref(false)
const isLoading = ref(false)
const successTips = ref("")

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)

/** methods **/
const handleCloseClick = () => {
  emits("close")

  // Meta Pixel 埋点 BNTO-1236
  fbq('track', 'Click', {
    content_name: 'Ad Waitlist Streamer On Browser', // 广告位的名称或标识
    content_type: 'ad',
    ad_position: 'homepage_top', // 你可以自定义的位置标识
    click_action: 'proactively_close_ad_streamer' // 描述点击的动作
  });
}
const handleEmailBlur = () => {
  // reset
  emailError.value = false
  emailErrorTips.value = ""
  successTips.value = ""
  // 必填校验
  if (!email.value) {
    emailError.value = true
    emailErrorTips.value = EMAIL_ERROR_TIPS_MAP.REQUIRED
    return
  }
  // 合法校验
  const valid = emailValid(email.value)
  if (!valid) {
    emailError.value = true
    emailErrorTips.value = EMAIL_ERROR_TIPS_MAP.FORMAT_ERROR
  }
}
const handleConfirm = async () => {

  // Meta Pixel 埋点 BNTO-1236
  fbq('track', 'Click', {
    content_name: 'Ad Waitlist Streamer On Browser', // 广告位的名称或标识
    content_type: 'ad',
    ad_position: 'homepage_top', // 你可以自定义的位置标识
    click_action: 'button_join_the_waitlist_click' // 描述点击的动作
  });

  if (!email.value) return;
  if (emailError.value) return;
  if (isLoading.value) return;
  try {
    isLoading.value = true
    await waitlistSignup({email: email.value})
    isLoading.value = false
    // successTips.value = "Thank you for signing up!"
    emits("success")

    // Meta Pixel 埋点 BNTO-1236
    fbq('track', 'CompleteRegistration', {
      content_name: 'Ad Waitlist Streamer On Browser', // 广告位的名称或标识
      content_type: 'ad',
      ad_position: 'homepage_top', // 你可以自定义的位置标识
      click_action: 'complete_the_signing_up' // 描述点击的动作
    });

    // BNTO - 1373
    window.location.href = "https://www.bnto.rent/landing/thank-you"

  } catch (e) {
    isLoading.value = false
    emailError.value = true
    emailErrorTips.value = e.message.content
    successTips.value = ""
  }
}

/** lifecycle **/
onMounted(() => {
  // Meta Pixel 埋点 BNTO-1236
  fbq('track', 'ViewContent', {
    content_name: 'Ad Waitlist Streamer On Browser', // 广告位的名称或标识
    content_type: 'ad',
    ad_position: 'homepage_top', // 你可以自定义的位置标识
  });
})
</script>

<template>
  <div class="module-streamer-waitlist-mobile" v-if="isMobile">
    <div class="button-close" @click="handleCloseClick">
      {{ $t("close") }}
      <img class="close" src="@/assets/img/icon-close-black.svg" alt="close">
    </div>
    <div class="content">
      <div class="subtitle">{{ subtitle }}</div>
      <div class="title">{{ title }}</div>
      <div class="desc">{{ desc }}</div>
      <div class="input-container">
        <module-input
          type="text"
          placeholder="Email address"
          v-model="email"
          name="email"
          :input-class="'streamer-waitlist-input'"
          :tips="successTips"
          :error-tips="emailErrorTips"
          :error="emailError"
          @blur="handleEmailBlur"
        />
        <div
          class="btn-primary-black btn-confirm"
          :class="{'btn-disabled-black': isLoading}"
          @click="handleConfirm"
        >
          join the waitlist
        </div>
      </div>
    </div>
  </div>
  <div class="module-streamer-waitlist-desktop" v-else>
    <img class="bg-iphone" src="@/assets/img/img-iphone-streamer-waitlist.png" alt="img">
    <div class="button-close" @click="handleCloseClick">
      {{ $t("close") }}
      <img class="close" src="@/assets/img/icon-close-black.svg" alt="close">
    </div>
    <div class="content">
      <div class="subtitle">{{ subtitle }}</div>
      <div class="title">{{ title }}</div>
      <div class="desc">{{ desc }}</div>
      <div class="input-container">
        <div class="input-decorator"/>
        <module-input
          type="text"
          placeholder="Email address"
          v-model="email"
          name="email"
          :container-class="`streamer-waitlist-input-container`"
          :input-class="'streamer-waitlist-input'"
          :tips="successTips"
          :error-tips="emailErrorTips"
          :error="emailError"
          @blur="handleEmailBlur"
        />
        <div
          class="btn-primary-black"
          :class="{'btn-disabled-black': isLoading}"
          @click="handleConfirm"
        >
          join the waitlist
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "src/assets/config";

.module-streamer-waitlist-mobile {
  .streamer-waitlist-input {
    height: 42px;
    background-color: white;
  }
}

.module-streamer-waitlist-desktop {
  .streamer-waitlist-input-container {
    width: 320px !important;
    position: absolute;
  }

  .streamer-waitlist-input {
    height: 42px;
    background-color: white;
  }
}
</style>

<style scoped lang="scss">
@import "src/assets/config";

.module-streamer-waitlist-mobile {
  width: 100%;
  background: url("@/assets/img/bg-streamer-waitlist-mobile.png") white top center no-repeat;
  background-size: cover;
  position: relative;
  display: flex;

  .button-close {
    position: absolute;
    top: 16px;
    right: 15px;

    font-family: Druk Wide Cy;
    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
    text-decoration: underline;

    display: flex;
    align-items: center;

    cursor: pointer;

    .close {
      width: 20px;
      height: 20px;
      margin-left: 4px;
    }
  }

  .content {
    margin-top: 45px;
    margin-bottom: 34px;
    width: 100%;
    padding: 0 25px;

    .subtitle {
      /* Eyebrow/medium */
      font-family: "TWK Lausanne";
      font-size: 14px;
      font-style: normal;
      font-weight: 650;
      line-height: 18px; /* 128.571% */
      letter-spacing: -0.056px;
      text-transform: uppercase;
    }

    .title {
      /* Headline/large */
      font-family: "Alternate Gothic No3 D";
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.36px;
      text-transform: uppercase;
      padding-top: 8px;
    }

    .desc {
      /* Text md/Regular */
      font-family: "TWK Lausanne";
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px; /* 150% */
      color: $color-gray-600;
      white-space: break-spaces;
      margin-top: 8px;
    }

    .input-container {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .btn-confirm {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

.module-streamer-waitlist-desktop {
  min-width: $content-1280-width;
  width: 100%;
  height: 395px;
  background: url("@/assets/img/bg-streamer-waitlist.png") white top center no-repeat;
  background-size: cover;
  position: relative;
  display: flex;

  .bg-iphone {
    width: 250px;
    position: absolute;
    bottom: 0;
    right: 125px;
  }

  .button-close {
    position: absolute;
    top: 35px;
    right: 22px;

    font-family: Druk Wide Cy;
    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
    text-decoration: underline;

    display: flex;
    align-items: center;

    cursor: pointer;

    .close {
      width: 20px;
      height: 20px;
      margin-left: 4px;
    }
  }

  .content {
    margin-top: 69px;
    margin-left: 94px;
    width: 60%;

    .subtitle {
      /* Eyebrow/medium */
      font-family: "TWK Lausanne";
      font-size: 14px;
      font-style: normal;
      font-weight: 650;
      line-height: 18px; /* 128.571% */
      letter-spacing: -0.056px;
      text-transform: uppercase;
    }

    .title {
      /* Headline/large */
      font-family: "Alternate Gothic No3 D";
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.36px;
      text-transform: uppercase;
      padding-top: 8px;
    }

    .desc {
      /* Text md/Regular */
      font-family: "TWK Lausanne";
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px; /* 150% */
      color: $color-gray-600;
      white-space: break-spaces;
      margin-top: 8px;
    }

    .input-container {
      margin-top: 40px;
      display: flex;
      gap: 8px;
      position: relative;

      .input-decorator {
        width: 320px;
        height: 42px;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25) !important;
      }
    }
  }
}
</style>
